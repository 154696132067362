import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  IconButton,
  Link,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTranslation } from 'react-i18next';

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(1.5)};
    top: ${theme.spacing(1.5)};
    z-index: 7;
  `,
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(13)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.9, 1.5, 0.7)};
    line-height: 1;
  `,
);

const CardWrapper = styled(Box)(
  ({ theme }) => `
      background: ${alpha(theme.colors.primary.main, 0.05)};
  `,
);

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `
        text-align: center;
        background: ${alpha(theme.colors.primary.main, 0.03)};

        .MuiTouchRipple-root {
          opacity: .2;
        }
  
        .MuiCardActionArea-focusHighlight {
          background: ${theme.colors.primary.main};
        }
  
        &:hover {
          .MuiCardActionArea-focusHighlight {
            opacity: .05;
          }
        }
  `,
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transform: translateY(0px);
        transition: ${theme.transitions.create(['color', 'transform'])};

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }

        &:hover {
            transform: translateY(-2px);
    
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
  `,
);

function TeachersPage() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Card variant="outlined">
      <CardWrapper
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          pt: 3,
          px: 3,
          pb: 7,
        }}
      >
        {/* <CardActions>
          <IconButton size="small" color="primary">
            <MoreHorizTwoToneIcon />
          </IconButton>
        </CardActions> */}
        <Avatar
          sx={{
            width: `${theme.spacing(14)}`,
            height: `${theme.spacing(14)}`,
            mb: 1.5,
            border: `${theme.colors.alpha.white[100]} solid 4px`,
            boxShadow: `0 2rem 8rem 0 ${alpha(
              theme.colors.alpha.black[100],
              0.05,
            )}, 
                            0 0.6rem 1.6rem ${alpha(
                              theme.colors.alpha.black[100],
                              0.15,
                            )}, 
                            0 0.2rem 0.2rem ${alpha(
                              theme.colors.alpha.black[100],
                              0.1,
                            )}`,
          }}
          src="/static/images/avatars/6.png"
        />
        <Link
          href="#"
          color="text.primary"
          underline="none"
          sx={{
            transition: `${theme.transitions.create(['color'])}`,
            fontSize: `${theme.typography.pxToRem(17)}`,

            '&:hover': {
              color: `${theme.colors.primary.main}`,
            },
          }}
          variant="h4"
        >
          Kamran Hajiyev
        </Link>
        <Box mt={1} mb={1.5}>
          <LabelWrapper
            sx={{
              background: `${theme.colors.success.main}`,
              color: `${theme.palette.getContrastText(
                theme.colors.success.dark,
              )}`,
            }}
          >
            {t('Online')}
          </LabelWrapper>
        </Box>
        <Typography variant="subtitle2">
          Kamran Hacıyev 400 dən çox tələbəyə dərs keçib və 5 ildən çox
          təcrübəyə malikdir.
        </Typography>
        <Typography variant="subtitle2">
          Sertifikatları: TESOL (Arizona State university), TEFL (Business
          English)
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            variant="contained"
            type="submit"
            startIcon={<WhatsAppIcon />}
            color="primary"
            href="https://api.whatsapp.com/send?text=Salam.%20%C4%B0ngilis%20dili%20d%C9%99rsl%C9%99ri%20haqq%C4%B1nda%20m%C9%99lumat%20almaq%20ist%C9%99yir%C9%99m.&phone=+994998989876"
          >
            İndi qoşul
          </Button>
        </Box>
      </CardWrapper>

      {/* <Box
          sx={{
            textAlign: 'center',
            mt: `-${theme.spacing(7)}`,
            mb: 3,
          }}
        >
          <Tooltip arrow placement="top" title="Facebook">
            <IconButtonWrapper
              sx={{
                boxShadow: `${theme.colors.shadows.primary}`,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.main,
                )}`,

                '&:hover': {
                  background: `${theme.colors.primary.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main,
                  )}`,
                },
                borderRadius: 50,
                width: theme.spacing(8),
                height: theme.spacing(8),
              }}
              color="primary"
              size="large"
            >
              <FacebookIcon fontSize="small" />
            </IconButtonWrapper>
          </Tooltip>
          <Tooltip arrow placement="top" title="Twitter">
            <IconButtonWrapper
              sx={{
                boxShadow: `${theme.colors.shadows.primary}`,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.main,
                )}`,

                '&:hover': {
                  background: `${theme.colors.primary.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main,
                  )}`,
                },
                borderRadius: 50,
                mx: 1.5,
                width: theme.spacing(8),
                height: theme.spacing(8),
              }}
              color="primary"
              size="large"
            >
              <TwitterIcon fontSize="small" />
            </IconButtonWrapper>
          </Tooltip>
          <Tooltip arrow placement="top" title="Instagram">
            <IconButtonWrapper
              sx={{
                boxShadow: `${theme.colors.shadows.primary}`,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.main,
                )}`,

                '&:hover': {
                  background: `${theme.colors.primary.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main,
                  )}`,
                },
                borderRadius: 50,
                width: theme.spacing(8),
                height: theme.spacing(8),
              }}
              color="primary"
              size="large"
            >
              <InstagramIcon fontSize="small" />
            </IconButtonWrapper>
          </Tooltip>
        </Box>
        <Box p={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <CardActionAreaWrapper
                  sx={{
                    p: 2,
                  }}
                >
                  <Text color="warning">
                    <AccountTreeTwoToneIcon
                      sx={{
                        mb: 1,
                      }}
                    />
                  </Text>
                  <Typography variant="h4">{t('Projects')}</Typography>
                  <Typography variant="subtitle2" noWrap>
                    {t('Newest tasks')}
                  </Typography>
                </CardActionAreaWrapper>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <CardActionAreaWrapper
                  sx={{
                    p: 2,
                  }}
                >
                  <Text color="success">
                    <ContactSupportTwoToneIcon
                      sx={{
                        mb: 1,
                      }}
                    />
                  </Text>
                  <Typography variant="h4">{t('Helpdesk')}</Typography>
                  <Typography variant="subtitle2" noWrap>
                    {t('Tickets overview')}
                  </Typography>
                </CardActionAreaWrapper>
              </Card>
            </Grid>
          </Grid>
        </Box> */}
    </Card>
  );
}

export default TeachersPage;
