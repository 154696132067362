import { AxiosResponse } from 'axios';

import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getDigests = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/units/digest`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
