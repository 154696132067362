import { Digests } from '../pages/digests';

const digestsRouter = {
  path: 'Proqramlar',
  children: [
    {
      path: '',
      element: <Digests />,
    },
  ],
};

export default digestsRouter;
