import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useCreateAnswer } from '../../hooks/use-create-answer';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
import useKeyPress from 'src/shared/hooks/use-key-press';

const QuicksandBtn = styled(IconButton)`
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
`;
const styles = {
  box: {
    width: '100%',
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingX: '18px',
  },
  card: {
    minHeight: '300px',
    maxWidth: '650px',
    minWidth: '300px',
    marginX: 'auto',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '10px',
  },
  correctAnswers: {
    marginTop: '10px',
  },
};

export const TextMultiInputs = ({ card }) => {
  const [answerState, setAnswerState] = useState({
    isCorrect: false,
    tryCount: 0,
    answered: false,
    nextStep: false,
  });

  const [inputValues, setInputValues] = useState(
    Array.from({ length: card?.corrects[0].split('-').length }, () => ({
      value: '',
      checked: false,
      isTrue: false,
    })),
  );

  const { answerMutate, isCreatingAnswer, data } = useCreateAnswer();

  const { moveToNext } = useContext(CardsContextV2);

  const inputValuesAreEmpty = inputValues.every(
    (input) => input.value.trim() === '',
  );

  const inputValueIsNotTrue = inputValues.some((field) => !field.isTrue);

  const checkAnswer = useCallback(() => {
    const correctAnswersArray = card.corrects.map((item) => item.split('-'));

    const isCorrectAnswer = inputValues.map((field) => field.value).join('-');

    if (inputValueIsNotTrue && !inputValuesAreEmpty) {
      setAnswerState((prevState) => {
        return {
          ...prevState,
          tryCount: prevState.tryCount + 1,
        };
      });
    }

    if (answerState.tryCount !== 3) {
      setInputValues((inputValues) => {
        return inputValues.map((valueObj, i) => {
          const isTrue = correctAnswersArray.some((correctAnswer) => {
            return valueObj.value === correctAnswer[i];
          });
          valueObj.checked = true;
          valueObj.isTrue = isTrue;
          valueObj.value = isTrue ? valueObj.value : '';

          return valueObj;
        });
      });

      if (!inputValuesAreEmpty && inputValueIsNotTrue) {
        answerMutate({
          card: card.id,
          response: isCorrectAnswer,
        });
      }
    }

    if (
      (inputValuesAreEmpty && !answerState.answered) ||
      (inputValuesAreEmpty &&
        inputValueIsNotTrue &&
        answerState.tryCount > 0 &&
        !answerState.nextStep)
    ) {
      setAnswerState((prevState) => {
        return {
          ...prevState,
          nextStep: true,
        };
      });
      answerMutate({
        card: card.id,
        response: '',
      });

      return;
    }

    if (
      !inputValuesAreEmpty &&
      !inputValueIsNotTrue &&
      !answerState.isCorrect
    ) {
      answerMutate({
        card: card.id,
        response: isCorrectAnswer,
      });
      return;
    }

    if (
      (inputValuesAreEmpty &&
        answerState.answered &&
        answerState.tryCount === 0) ||
      answerState.isCorrect ||
      (!answerState.isCorrect && answerState.tryCount === 3) ||
      answerState.nextStep
    ) {
      moveToNext();
      setAnswerState({
        isCorrect: false,
        tryCount: 0,
        answered: false,
        nextStep: false,
      });
    }
  }, [inputValues, answerState, card.id, answerMutate, moveToNext]);

  useEffect(() => {
    if (data) {
      const answerData = data?.data?.data;

      setAnswerState((prevState) => {
        return {
          ...prevState,
          isCorrect: answerData.isCorrect,
          answered: true,
        };
      });
    }
  }, [data]);

  const handleChange = useCallback((value, index) => {
    setInputValues((prevValues) =>
      prevValues.map((field, i) => (i === index ? { ...field, value } : field)),
    );
  }, []);

  const getCardButtonLabel = () => {
    if (
      answerState.isCorrect ||
      (answerState.answered &&
        inputValuesAreEmpty &&
        answerState.tryCount === 0) ||
      answerState.nextStep
    ) {
      return 'Növbəti';
    }

    const hasInputValues = inputValues.some(
      (valueObj) => valueObj.value.trim() !== '' && answerState.tryCount < 3,
    );

    return hasInputValues
      ? 'Yoxla'
      : answerState.tryCount === 3 || answerState.isCorrect
      ? 'Növbəti'
      : 'Bilmirəm';
  };

  const getFieldColor = useCallback(
    (i) => {
      if (
        inputValues[i].value.trim() === '' &&
        inputValues[i].checked &&
        !inputValues[i].isTrue
      ) {
        return 'error';
      }
      if (inputValues[i].isTrue) {
        return 'success';
      } else {
        return 'primary';
      }
    },
    [inputValues],
  );

  const renderCorrectAnswers = useCallback(() => {
    const { isCorrect, answered, tryCount, nextStep } = answerState;

    const isCorrectAnswer =
      (inputValuesAreEmpty && answered && tryCount === 0) ||
      (!isCorrect && tryCount === 3) ||
      (isCorrect && !isCreatingAnswer) ||
      (nextStep && !isCreatingAnswer);
    return isCorrectAnswer ? (
      <Typography sx={styles.correctAnswers}>
        Doğru cavab: {card.corrects[0].split('-').join(',')}
      </Typography>
    ) : null;
  }, [answerState, inputValues, card.corrects, isCreatingAnswer]);

  useKeyPress(checkAnswer);

  return (
    <>
      <Box sx={styles.box}>
        <Card sx={styles.card}>
          <Box>
            {card.text.split('@input@').map((item, i, arr) => {
              return (
                <React.Fragment key={i}>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      position: 'relative',
                      top: '0',
                      lineHeight: '35px',
                    }}
                  >
                    {item}
                  </Typography>
                  {i !== arr.length - 1 && (
                    <TextField
                      id={`id-${i}`}
                      name={`name-${i}`}
                      value={inputValues[i].value}
                      color={getFieldColor(i)}
                      focused={
                        answerState.answered ||
                        inputValues[i].checked ||
                        !inputValues[i].isTrue ||
                        inputValues[i].isTrue
                      }
                      size="small"
                      onChange={(e) => handleChange(e.target.value, i)}
                      inputProps={{
                        disabled:
                          (inputValues[i].checked && inputValues[i].isTrue) ||
                          answerState.tryCount === 3 ||
                          (inputValuesAreEmpty &&
                            answerState.answered &&
                            answerState.tryCount === 0) ||
                          answerState.nextStep,
                      }}
                      sx={{
                        minWidth: '50px',
                        maxWidth: '80px',
                        margin: '3px 0',
                      }}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
            {renderCorrectAnswers()}
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <CardActions>
              <QuicksandBtn
                color="primary"
                size="small"
                sx={{ fontSize: '14px' }}
                disabled={isCreatingAnswer}
                onClick={checkAnswer}
              >
                {isCreatingAnswer && (
                  <CircularProgress size={14} sx={{ mr: 0.5 }} />
                )}
                {getCardButtonLabel()}
              </QuicksandBtn>
            </CardActions>
          </Box>
        </Card>
      </Box>
    </>
  );
};
