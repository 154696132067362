const azJSON = {
  lessons: {
    title: 'dərslər',
  },
  digests: {
    title: 'Proqramlar',
  },
  myResults: {
    title: 'nəticələrim',
  },
  terms: {
    title: 'Şərtlər və Qaydalar',
  },
  myFriends: {
    title: 'dostlarım',
  },
  popover: {
    title: 'profil',
    signOut: 'çıxış et',
  },
  pages: {
    home: 'Ana səhifə',
  },
  heading: {
    general: 'Ümumi',
    management: 'İdarəetmə',
  },
  profile: {
    title: 'Profil',
    changeCover: 'Örtüyü dəyişdirin',
    tabs: {
      myAccount: 'mənim hesabım',
      aboutMe: 'haqqımda',
    },
  },
  inputs: {
    labels: {
      changePassword: 'Parolu dəyişdirin',
      changeEmail: 'E-poçtu dəyişdirin',
      currentPassword: 'Hazırkı şifrə',
      password: 'Şifrə',
      passwordConfirm: 'Şifrəni təsdiqləyin',
      email: 'E-poçt',
      name: 'Ad',
      surname: 'Soyad',
      fatherName: 'Ata adı',
      gender: 'Cins',
      fullName: 'Tam adı',
      dateOfBirth: 'Doğum tarixi',
      numberOne: '1-ci nömrə',
      numberTwo: '2-ci nömrə',
      date: 'Tarix',
      university: 'Universitet',
      interests: 'Maraqlar',
      price: 'məbləğ',
      permissionName: 'icazə adı',
      description: 'ətraflı',
      code: 'kod',
      tag: 'etiket adı',
      category: 'kateqoriya adı',
      amount: 'məbləğ',
      type: 'tipi',
      beforeEventDays: 'fəaliyyət günlərindən əvvəl',
      action: 'fəaliyyət adı',
      task: 'tapşırıq adı',
      tourName: 'turun adı',
      aboutTour: 'tur haqqında',
      additionalNotes: 'əlavə qeydlər',
      country: 'ölkə',
      days: 'gün',
      daysToDeadline: 'son tarixə qədər gün',
      difficulty: 'çətinlik',
      equipmentName: 'avadanlıq adı',
      brand: 'marka',
      model: 'model',
      size: 'ölçü',
      color: 'rəng',
      quantity: 'say',
      discount: 'endirim',
      purchasePrice: 'alış qiyməti',
      sellingPrice: 'satış qiyməti',
      rentPrice: 'icarə qiyməti',
      inventoryName: 'ehtiyat adı',
      rentalCode: 'icarə kodu',
      totalCost: 'ümumi xərc',
      startDate: 'başlama tarixi',
      endDate: 'bitmə tarixi',
      finishDate: 'bitirmə tarixi',
      customer: 'müştəri',
      referal: 'referans',
      exacutedBy: 'yerinə yetirən',
      user: 'istifadəçi',
      certificateCode: 'sertifikat kodu',
      createdBy: 'əlavə etdi',
      allUsers: 'bütün iştirakçılar',
      terms: 'Şərtlər və Qaydalar',
    },
    placeholders: {},
  },
  buttons: {
    confirm: 'təsdiq et',
    add: 'əlavə et',
    addEvent: 'tədbir əlavə et',
    addUser: 'istifadəçi əlavə et',
    delete: 'sil',
    cancel: 'ləğv et',
    archive: 'arxiv et',
    addPermission: 'icazə əlavə et',
    edit: 'redaktə et',
    addTag: 'etiket əlavə et',
    closeWindow: 'pəncərəni bağla',
    addExpense: 'xərc əlavə et',
    addTransaction: 'əməliyyat əlavə et',
    addAction: 'fəaliyyət əlavə et',
    addNewAction: 'yeni fəaliyyət əlavə et',
    addTask: 'tapşırıq əlavə et',
    addNewTask: 'yeni tapşırıq əlavə et',
    addNewTaskToParticipants: 'iştirakçılara yeni tapşırıq əlavə et',
    addTour: 'yeni tur əlavə et',
    addEquipment: 'yeni avadanlıq əlavə et',
    addNewEventTask: 'tədbirə tapşırıq əlavə et',
    addInventory: 'ehtiyat əlavə et',
    addRental: 'icarə əlavə et',
    addCertificate: 'sertifikat əlavə et',
    continue: 'davam et',
    check: 'yoxla',
    show: 'göstər',
    iDontKnow: 'bilmirəm',
    next: 'növbəti',
    register: 'qeydiyyatı tamamla',
  },
  yup: {
    currentPassword: 'Cari şifrə tələb olunur',
    password: {
      required: 'Şifrə tələb olunur',
      min: 'Şifrə 6 simvoldan az ola bilməz.',
    },
    passwordConfirm: {
      required: 'Təsdiq şifrəsi tələb olunur.',
      correctly: 'Təkrarlanan şifrəyə uyğun gəlmir',
    },
    email: {
      required: 'E-poçt tələb olunur',
      correctly: 'E-poçt düzgün daxil edilməyib',
    },
    inputs: {
      name: 'Ad tələb olunur',
      surname: 'Soyad tələb olunur',
      fatherName: 'Ata adı tələb olunur',
      gender: 'Cins tələb olunur',
      university: 'Universitet tələb olunur',
      date: 'Tarix tələb olunur',
      price: 'Məbləğ tələb olunur',
      participant: 'İştirakçı tələb olunur',
      permissionName: 'icazə adı tələb olunur',
      description: 'ətraflı məlumat tələb olunur',
      permissionCode: 'kod tələb olunur',
      amount: 'məbləğ tələb olunur',
      tag: 'etiket tələb olunur',
      person: 'istifadəçi tələb olunur',
      beforeEventDays: 'gün tələb olunur',
      task: 'tapşırıq adı tələb olunur',
      action: 'fəaliyyət adı tələb olunur',
      tourName: 'turun adı tələb olunur',
      brand: 'marka tələb olunur',
      quantity: 'say tələb olunur',
      discount: 'endirim tələb olunur',
      purchasePrice: 'alış qiyməti tələb olunur',
      sellingPrice: 'satış qiyməti tələb olunur',
      rentPrice: 'icarə qiyməti tələb olunur',
      customer: 'müştəri tələb olunur',
      totalCost: 'ümumi xərc tələb olunur',
      totalCostMin: 'məbləğ minimum 1 olmalıdır',
      startDate: 'başlama tarixi tələb olunur',
      endDate: 'bitmə tarixi tələb olunur',
      exacutedBy: 'yerinə yeriən tələb olunur',
      dateOfBirth: 'doğum tarixi tələb olunur',
    },
    terms: {
      required: 'Siz şərtlər və qaydaları qəbul etməlisiniz.',
    },
  },
  login: {
    signIn: 'daxil ol',
    info: 'hesabınıza daxil olmaq üçün aşağıdakı sahələri doldurun',
    lostPassword: 'şifrənizi unutmusunuz?',
    signUp: 'qeydiyyatdan keçin',
    noAccount: 'hesabınız yoxur?',
    haveAccount: 'hesabınız var?',
  },
  recoverPassword: {
    title: 'şifrəni bərpa edin',
    info: 'şifrənizi yeniləmək üçün istifadə olunan e-poçtu daxil edin',
    sendPassword: 'yeni şifrəni göndər',
    signInAgain: 'yenidən daxil olmaq istəyirsiniz?',
    clickHere: 'bura click edin',
  },
  languageSwitcher: {
    title: 'dil',
    tooltip: 'dili dəyiş',
  },
  modal: {
    delete: 'silmək istədiyinizdən əminsiniz?',
    archive: 'arxiv etmək istədiyinizdən əminsiniz?',
    amountAfterDiscount: 'endirimdən sonrakı məbləğ',
    addAmount: 'məbləğ əlavə et',
    makePayment: 'ödəniş et',
    allTasksOfParticipant: 'iştirakçıya ayrılan tapşırıqlar',
    addNewAction: 'yeni fəaliyyət əlavə et',
    addNewTask: 'yeni tapşırıq əlavə et',
    addNewParticipantTask: 'iştirakçılara yeni tapşırıq əlavə et',
    addNewEventTask: 'tədbirə tapşırıq əlavə et',
    details: 'ətraflı məlumat',
    addPhoto: 'şəkil əlavə et',
    addInfoToTask: 'tapşırığa məlumatları əlavə et',
  },
  table: {
    name: 'ad',
    fullName: 'tam adı',
    category: 'kateqoriya',
    type: 'tipi',
    amount: 'məbləğ',
    description: 'ətraflı',
    actions: 'əməliyyat',
    code: 'kod',
    tasks: 'tapşırıqlar',
    beforeEventDays: 'fəaliyyət günlərindən əvvəl',
    brand: 'marka',
    model: 'model',
    color: 'rəng',
    size: 'ölçü',
    quantity: 'say',
    discount: 'endirim',
    purchasePrice: 'alış qiyməti',
    sellingPrice: 'satış qiyməti',
    rentPrice: 'icarə qiyməti',
    totalCost: 'ümumi xərc',
    user: 'istifadəçi',
    created: 'əlavə etdi',
  },
  permissions: {
    title: 'icazələr',
    premissionName: 'icazə adı',
    premissonCode: 'kod',
    premissonDescription: 'açıqlama',
    addPermission: 'icazə əlavə et',
  },
  actionButtons: {
    view: 'ətraflı',
    delete: 'sil',
    archive: 'arxiv',
    discount: 'endirim et',
    addAmount: 'məbləğ əlavə et',
    edit: 'redaktə et',
    confirm: 'təsdiq et',
    allTasks: 'ümumi tapşırıqlar',
    add: 'əlavə et',
    selectUser: 'iştirakçı seç',
    endDate: 'bitmə tarixi',
  },
};

export default azJSON;
