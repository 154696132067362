import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Learning from 'src/content/dashboards/Learning';
import cardsRouter from 'src/models/router';
import digestsRouter from 'src/modules/digests/router';
import myFriendsRouter from 'src/modules/my-friends/router';
import myResultsRouter from 'src/modules/my-results/router';
import teachersRouter from 'src/modules/teachers/router';
import unitsRouter from 'src/modules/units/router';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Analytics = Loader(
  lazy(() => import('src/content/dashboards/Analytics')),
);

const Students = Loader(lazy(() => import('src/content/dashboards/Students')));
const ProfilePage = Loader(
  lazy(() => import('src/modules/profile/pages/profile')),
);

const dashboardsRoutes = [
  {
    path: '',
    element: <Navigate to="/dashboard/digests" />,
  },
  unitsRouter,
  myResultsRouter,
  myFriendsRouter,
  cardsRouter,
  digestsRouter,
  teachersRouter,
  {
    path: '',
    element: <Analytics />,
  },
  {
    path: 'profile',
    element: <ProfilePage />,
  },
  {
    path: '/dashboard/learning',
    element: <Learning />,
  },
];

export default dashboardsRoutes;
