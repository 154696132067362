import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { Box, Card, CircularProgress, Grid, Typography } from '@mui/material';
import { IUnit } from 'src/types';
import { DigestBox } from '../components/digest-box';
import { useDigests } from '../hooks/use-digests';

export const DigestsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useDigests();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('digests.title'),
      href: '/dashboard/proqramlar',
    },
  ];

  return (
    <PageWrapper routes={routes} isPaper={false} title={t('digests.title')}>
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Box>
          <Grid container spacing={2}>
            {data?.map((digest: any) => (
              <DigestBox key={digest.id} digest={digest} />
            ))}
          </Grid>
        </Box>
      )}
    </PageWrapper>
  );
};
