import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { TImageCardQuizMultiSelectionFields } from '../../types';
import { useCreateAnswer } from '../../hooks/use-create-answer';
import { imageLazyLoading } from 'src/shared/components/image-lazy-loading';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
import { Blurhash } from 'react-blurhash';
import useKeyPress from 'src/shared/hooks/use-key-press';

const QuicksandBtn = styled(IconButton)`
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
`;
export const ImageMultiSelection = ({
  card,
}: {
  card: TImageCardQuizMultiSelectionFields;
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [answerState, setAnswerState] = useState({
    corrects: [],
    isCorrect: false,
    tryCount: 0,
    answered: false,
  });

  const { currentImage, loading } = imageLazyLoading(card?.images[0]);

  const { moveToNext } = useContext(CardsContextV2);

  const { answerMutate, isCreatingAnswer, data } = useCreateAnswer();

  const handleConfirm = useCallback(async () => {
    if (answerState.answered) {
      moveToNext();
      setAnswerState({
        corrects: [],
        isCorrect: false,
        tryCount: 0,
        answered: false,
      });
      setSelectedAnswer('');
    } else {
      await answerMutate({
        card: card.id,
        response: selectedAnswer,
      });
    }
  }, [
    answerState.answered,
    moveToNext,
    setAnswerState,
    setSelectedAnswer,
    answerMutate,
    card.id,
    selectedAnswer,
  ]);

  useEffect(() => {
    if (data) {
      const answerData = data?.data?.data;

      setAnswerState((s) => {
        return {
          corrects: answerData.corrects,
          isCorrect: answerData.isCorrect,
          tryCount: s.tryCount + 1,
          answered: true,
        };
      });
    }
  }, [data]);

  function getCardButtonLabel() {
    if (answerState.answered) {
      return 'Növbəti';
    } else if (selectedAnswer) {
      return 'Yoxla';
    } else {
      return 'Bilmirəm';
    }
  }

  const getButtonVariant = (isSelected, isCorrectAnswer) => {
    if (answerState.corrects?.length > 0) {
      return isCorrectAnswer ? 'contained' : 'outlined';
    } else {
      return isSelected ? 'contained' : 'outlined';
    }
  };

  const getButtonColor = (isSelected, isCorrectAnswer) => {
    if (isSelected && answerState.corrects?.length > 0) {
      return isCorrectAnswer ? 'success' : 'error';
    } else {
      return 'primary';
    }
  };

  const styles = {
    box: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5rem 0',
      paddingX: '18px',
      paddingBottom: '4rem',
    },
    mediaBox: {
      minWidth: '250px',
      maxWidth: '400px',
      minHeight: '150px',
      maxHeight: '400px',
      marginTop: '20px',
      overflow: 'hidden',
      borderRadius: '10px',
      margin: '16px auto',
    },
    card: {
      minHeight: '200px',
      maxWidth: '600px',
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginX: 'auto',
      paddingX: '16px',
    },
    cardContent: {
      paddingX: '0px',
    },
    buttonsWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      marginTop: '10px',
    },
    button: {
      width: '100%',
    },
    disabledButton: {
      width: '100%',
      opacity: 0.6,
    },
    blurCard: {
      minWidth: '400px',
      height: '380px',
      display: loading ? 'block' : 'none',

      '@media (max-width: 426px)': {
        width: '100%',
        height: '341px',
      },
      '@media (max-width: 376px)': {
        height: '293px',
      },
      '@media (max-width: 321px)': {
        height: '256px',
      },
    },
  };

  useKeyPress(handleConfirm);

  return (
    <>
      <Box sx={styles.box}>
        <Card sx={styles.card}>
          <Box sx={{ width: '100%' }}>
            <Box sx={styles.mediaBox}>
              <Box sx={styles.blurCard}>
                <Blurhash
                  hash="LARfUmxux]H?%MkCfkju?^RjRP%g"
                  style={{ width: '100%', height: '100%' }}
                  resolutionX={32}
                  resolutionY={32}
                  punch={1}
                />
              </Box>
              <CardMedia
                style={{
                  filter: `${loading ? 'blur(20px)' : ''}`,
                  transition: '0.4s filter linear',
                  width: '100%',
                  background: '#ffffff',
                  display: loading ? 'none' : 'block',
                }}
                component="img"
                image={currentImage}
              />
            </Box>
          </Box>
          <CardContent>
            {card.text ? (
              <Typography
                variant="body1"
                fontSize="16px"
                color="text.secondary"
              >
                {card.text}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                fontSize="16px"
                color="text.secondary"
                dangerouslySetInnerHTML={{ __html: card.body }}
              />
            )}
            {card.options.length > 0 && (
              <Box sx={styles.buttonsWrapper}>
                {card.options.map((option, index) => {
                  const isSelected = selectedAnswer === option;
                  const isCorrectAnswer = answerState.corrects.includes(option);

                  return (
                    <Button
                      key={index}
                      onClick={() => {
                        setSelectedAnswer((s) =>
                          answerState.tryCount >= 1 ? s : option,
                        );
                      }}
                      sx={
                        answerState.answered
                          ? isCorrectAnswer
                            ? styles.button
                            : styles.disabledButton
                          : styles.button
                      }
                      variant={getButtonVariant(isSelected, isCorrectAnswer)}
                      color={getButtonColor(isSelected, isCorrectAnswer)}
                    >
                      {option}
                    </Button>
                  );
                })}
              </Box>
            )}
          </CardContent>
          <CardActions>
            <QuicksandBtn
              onClick={handleConfirm}
              color="primary"
              size="small"
              sx={{ fontSize: '14px' }}
              disabled={isCreatingAnswer}
            >
              {isCreatingAnswer && (
                <CircularProgress size={14} sx={{ mr: 1 }} />
              )}
              {getCardButtonLabel()}
            </QuicksandBtn>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};
