import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { Box, Card, CircularProgress, Grid, Typography } from '@mui/material';
import { useUnits } from '../hooks/use-units';
import { IUnit } from 'src/types';
import { UnitBox } from '../components/unit-box';

export const UnitsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useUnits();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('lessons.title'),
      href: '/dashboard/lessons',
    },
  ];

  return (
    <PageWrapper routes={routes} isPaper={false} title={t('lessons.title')}>
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Box>
          <Typography p={2} variant="h4">
            Birinci həftə
          </Typography>
          <Grid container spacing={2}>
            {data?.map((unit: IUnit) => (
              <UnitBox key={unit.id} unit={unit} />
            ))}
          </Grid>
        </Box>
      )}
    </PageWrapper>
  );
};
