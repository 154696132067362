import { useEffect, useState } from 'react';

export const imageLazyLoading = (image) => {
  const [currentImage, setCurrentImage] = useState(image);
  const [loading, setLoading] = useState(true);

  const fetchImage = (src) => {
    const loadingImage = new Image();
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
    loadingImage.src = src;
  };

  useEffect(() => {
    fetchImage(image);
  }, [image]);

  return {
    currentImage,
    loading,
  };
};
