import TermsPage from '../pages/terms';

const termsRouter = {
  path: 'terms',
  children: [
    {
      path: '',
      element: <TermsPage />,
    },
  ],
};

export default termsRouter;
