import { MyResults } from '../pages/my-results';

const myResultsRouter = {
  path: 'my-results',
  children: [
    {
      path: '',
      element: <MyResults />,
    },
  ],
};

export default myResultsRouter;
