import { useContext } from 'react';
import { useUnit } from 'src/modules/units/hooks/use-unit';
// import { TextQuestion } from '../components/v2/text-question';
import { Box, Card, CircularProgress } from '@mui/material';
import { ImageText } from '../components/v2/image-text';
import { ImageMultiSelection } from '../components/v2/image-multi-selection';
// import Progressbar from 'src/modules/progressbar';
import { TextMultiInputs } from '../components/v2/text-multi-inputs';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
// import Progressbar from 'src/modules/progressbar';
import { Text } from '../components/v2/text';
import AudioWrite from '../components/v2/audio-write';
import { ImageWrite } from '../components/v2/image-write';
import VideoWrite from '../components/v2/video-write';
import { VideoMultiSelection } from '../components/v2/video-multi-selection';
import AudioRead from '../components/v2/audio-read';
// import { DragDrop } from '../components/drag-drop';

enum CardsTypes {
  Text = 'text',
  ImageText = 'image-text',
  ImageMultiSelection = 'image-multi-selection',
  ImageWrite = 'image-write',
  VideoWrite = 'video-write',
  TextMultiInputs = 'text-multi-inputs',
  AudioWrite = 'audio-write',
  VideoMultiSelection = 'video-multi-selection',
  AudioRead = 'audio-read',
}

function CardManager() {
  const { isLoading } = useUnit();

  const { cards, index } = useContext(CardsContextV2);

  if (isLoading) {
    return (
      <Box sx={{ maxWidth: '1000px', margin: '40px auto' }}>
        <Card
          sx={{
            height: { md: '500px', sm: '400px', xs: '300px' },
            margin: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      </Box>
    );
  }

  const card = cards[index];
  let currentCard;
  if (card?.type === CardsTypes.ImageText) {
    currentCard = <ImageText card={card} />;
  }
  if (card?.type === CardsTypes.Text) {
    currentCard = <Text card={card} />;
  }
  if (card?.type === CardsTypes.ImageMultiSelection) {
    currentCard = <ImageMultiSelection card={card} />;
  }
  if (card?.type === CardsTypes.ImageWrite) {
    return <ImageWrite card={card} />;
  }

  if (card?.type === CardsTypes.VideoWrite) {
    return <VideoWrite card={card} />;
  }

  if (card?.type === CardsTypes.TextMultiInputs) {
    currentCard = <TextMultiInputs card={card} />;
  }

  if (card?.type === CardsTypes.AudioWrite) {
    return <AudioWrite card={card} />;
  }

  if (card?.type === CardsTypes.AudioRead) {
    return <AudioRead card={card} />;
  }

  if (card?.type === CardsTypes.VideoMultiSelection) {
    return <VideoMultiSelection card={card} />;
  }
  // const percentage = Math.round(((index + 1) / cards.length) * 100);

  return (
    <>
      {currentCard}
      {/* <Progressbar percentage={percentage > 100 ? 100 : percentage} /> */}
    </>
  );
}

export default CardManager;
