import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
// import { TUnitsRoot } from '../../../types';
import { getUnits } from 'src/modules/units/services';

export const useUnits = () => {
  const { data, isLoading } = useQuery({
    queryKey: 'get-all-units-per-user',
    queryFn: getUnits,
    select: ({ data }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  return {
    data,
    isLoading,
  };
};
