import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';

export const MyFriendsContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('myFriends.title'),
      href: '/dashboard/myFriends',
    },
  ];

  return (
    <PageWrapper routes={routes} isPaper={false} title={t('myFriends.title')}>
      test
    </PageWrapper>
  );
};
