import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { useCallback, useContext } from 'react';
import { TImageQuizCardFields } from '../../types';
import { imageLazyLoading } from 'src/shared/components/image-lazy-loading';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
import { useCreateAnswer } from '../../hooks/use-create-answer';
import { Blurhash } from 'react-blurhash';
import useKeyPress from 'src/shared/hooks/use-key-press';

const QuicksandBtn = styled(IconButton)`
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

export const ImageText = ({ card }: { card: TImageQuizCardFields }) => {
  const { t } = useTranslation();

  const { currentImage, loading } = imageLazyLoading(card?.images[0]);

  const { moveToNext } = useContext(CardsContextV2);
  const { answerMutate, isCreatingAnswer, data } = useCreateAnswer();

  const styles = {
    box: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5rem 0',
      paddingX: '18px',
    },
    mediaBox: {
      minWidth: '250px',
      maxWidth: '400px',
      minHeight: '150px',
      maxHeight: '400px',
      marginTop: '20px',
      overflow: 'hidden',
      borderRadius: '10px',
      margin: '16px auto',
    },
    card: {
      minHeight: '300px',
      maxWidth: '600px',
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginX: 'auto',
      paddingX: '16px',
    },
    cardContent: {
      paddingX: '0px',
    },
    blurCard: {
      minWidth: '400px',
      height: '380px',
      display: loading ? 'block' : 'none',

      '@media (max-width: 426px)': {
        width: '100%',
        height: '341px',
      },
      '@media (max-width: 376px)': {
        height: '293px',
      },
      '@media (max-width: 321px)': {
        height: '256px',
      },
    },
  };

  const handleClick = useCallback(() => {
    answerMutate({
      card: card.id,
      response: '',
    });
    moveToNext();
  }, [answerMutate, card.id, moveToNext]);

  useKeyPress(handleClick);

  return (
    <>
      <Box sx={styles.box}>
        <Card sx={styles.card}>
          <Box sx={{ width: '100%' }}>
            <Box sx={styles.mediaBox}>
              <Box sx={styles.blurCard}>
                <Blurhash
                  hash="LEHLk~WB2yk8pyo0adR*.7kCMdnj"
                  style={{ width: '100%', height: '100%' }}
                  resolutionX={32}
                  resolutionY={32}
                  punch={1}
                />
              </Box>
              <CardMedia
                style={{
                  filter: `${loading ? 'blur(20px)' : ''}`,
                  transition: '0.4s filter linear',
                  width: '100%',
                  background: '#ffffff',
                  display: loading ? 'none' : 'block',
                }}
                component="img"
                image={currentImage}
              />
            </Box>
          </Box>
          <CardContent>
            {card.text ? (
              <Typography
                variant="body1"
                fontSize="16px"
                color="text.secondary"
              >
                {card.text}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                fontSize="16px"
                color="text.secondary"
                dangerouslySetInnerHTML={{ __html: card.body }}
              />
            )}
          </CardContent>
          <CardActions>
            <QuicksandBtn onClick={handleClick} color="primary" size="small">
              Davam et
            </QuicksandBtn>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};
