import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Typography,
  styled,
  TextField,
} from '@mui/material';
import { imageLazyLoading } from 'src/shared/components/image-lazy-loading';
import { useCreateAnswer } from '../../hooks/use-create-answer';
import { TTypeImageWriteCardFields } from '../../types';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
import useKeyPress from 'src/shared/hooks/use-key-press';
import { useTranslation } from 'react-i18next';

const QuicksandBtn = styled(IconButton)`
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

export const ImageWrite = ({ card }: { card: TTypeImageWriteCardFields }) => {
  const { t } = useTranslation();
  const { answerMutate, isCreatingAnswer, data } = useCreateAnswer();
  const { moveToNext } = useContext(CardsContextV2);

  const [answerState, setAnswerState] = useState({
    corrects: [],
    isCorrect: false,
    tryCount: 0,
    answered: false,
    answerFieldValue: '',
    nextStep: false,
  });

  const styles = {
    box: {
      width: '100%',
      height: '90vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingX: '18px',
    },
    mediaBox: {
      position: 'relative',
      minWidth: '250px',
      maxWidth: '400px',
      minHeight: '150px',
      maxHeight: '400px',
      marginX: '16px',
      marginTop: '20px',
      overflow: 'hidden',
      borderRadius: '10px',
    },
    videoPlayerIcon: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      inset: 0,
      zIndex: 111,
    },
    card: {
      minHeight: '300px',
      maxWidth: '600px',
      minWidth: '300px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginX: 'auto',
    },
    correctAnswers: {
      marginTop: '10px',
    },
  };

  const { currentImage, loading } = imageLazyLoading(card?.images[0]);

  const handleConfirm = useCallback(() => {
    const {
      answerFieldValue,
      tryCount,
      isCorrect,
      answered,
      nextStep,
      corrects,
    } = answerState;

    if (answerFieldValue.trim() !== '' && tryCount !== 3) {
      setAnswerState((prevState) => ({
        ...prevState,
        tryCount: prevState.tryCount + 1,
      }));
    }

    if (
      isCorrect ||
      tryCount === 2 ||
      (tryCount === 0 && answered) ||
      (tryCount > 0 && corrects.length > 0 && answered && nextStep)
    ) {
      moveToNext();
      setAnswerState({
        corrects: [],
        isCorrect: false,
        tryCount: 0,
        answered: false,
        answerFieldValue: '',
        nextStep: false,
      });
    } else if (
      answerFieldValue.trim() === '' &&
      tryCount > 0 &&
      !isCorrect &&
      answered
    ) {
      answerMutate({
        card: card.id,
        response: answerFieldValue.toLowerCase(),
      });
      setAnswerState((prevState) => ({
        ...prevState,
        nextStep: true,
      }));
    } else {
      answerMutate({
        card: card.id,
        response: answerFieldValue.toLowerCase(),
      });
    }
  }, [answerState, moveToNext, setAnswerState, answerMutate, card.id]);

  useEffect(() => {
    if (data) {
      const { corrects, isCorrect } = data?.data?.data;
      setAnswerState((prevState) => ({
        ...prevState,
        corrects,
        isCorrect,
        answered: true,
      }));
    }
  }, [data]);

  function getDisabledField() {
    return (
      answerState.isCorrect ||
      (answerState.answered && answerState.tryCount === 3) ||
      (answerState.answerFieldValue.trim() === '' &&
        answerState.corrects.length > 0 &&
        !answerState.isCorrect &&
        answerState.tryCount === 0) ||
      answerState.nextStep
    );
  }

  function getCardButtonLabel() {
    if (
      (answerState.answered && answerState.tryCount === 2) ||
      answerState.isCorrect ||
      (answerState.answerFieldValue.trim() === '' &&
        answerState.corrects.length > 0 &&
        !answerState.isCorrect &&
        answerState.tryCount === 0) ||
      answerState.nextStep
    ) {
      return 'Növbəti';
    } else if (
      answerState.answerFieldValue.trim() !== '' &&
      answerState.tryCount < 2
    ) {
      return answerState.tryCount === 0 ? 'Yoxla' : 'Yenidən sına';
    } else {
      return 'Bilmirəm';
    }
  }

  function getFieldColor() {
    if (answerState.isCorrect) {
      return 'success';
    } else if (
      answerState.answerFieldValue.trim() !== '' &&
      answerState.answered &&
      !answerState.isCorrect
    ) {
      return 'error';
    } else {
      return 'primary';
    }
  }
  function renderCorrectAnswers() {
    const {
      answerFieldValue,
      isCorrect,
      answered,
      corrects,
      tryCount,
      nextStep,
    } = answerState;

    const isCorrectAnswer =
      (answerFieldValue.trim().length === 0 && answered && tryCount === 0) ||
      (!isCorrect && tryCount === 2) ||
      (isCorrect && !isCreatingAnswer) ||
      (nextStep && !isCreatingAnswer);

    return (
      isCorrectAnswer && (
        <Typography sx={styles.correctAnswers}>
          {answerState.isCorrect
            ? 'Doğrudur'
            : isCreatingAnswer
            ? ''
            : `Doğru cavab: ${corrects[0]}`}
        </Typography>
      )
    );
  }
  useKeyPress(handleConfirm);

  return (
    <Box
      sx={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingX: '18px',
      }}
    >
      <Card
        sx={{
          minHeight: '300px',
          maxWidth: '600px',
          minWidth: '300px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginX: 'auto',
        }}
      >
        <Box sx={{ marginX: 'auto' }}>
          <Box
            sx={{
              minWidth: '250px',
              maxWidth: '400px',
              minHeight: '150px',
              maxHeight: '400px',
              marginX: '16px',
              marginTop: '20px',
              overflow: 'hidden',
              borderRadius: '10px',
            }}
          >
            <CardMedia
              style={{
                filter: `${loading ? 'blur(20px)' : ''}`,
                transition: '0.4s filter linear',
                width: '100%',
                background: '#ffffff',
              }}
              component="img"
              image={currentImage}
            />
          </Box>
        </Box>
        <CardContent>
          <Typography
            variant="body1"
            fontSize="16px"
            color="text.secondary"
            dangerouslySetInnerHTML={{ __html: card.text || card.body }}
          />
          <TextField
            fullWidth
            value={answerState.answerFieldValue}
            onChange={(e) =>
              setAnswerState((prevState) => ({
                ...prevState,
                answerFieldValue: e.target.value,
              }))
            }
            type="text"
            inputProps={{ disabled: getDisabledField() }}
            focused={answerState.answered}
            color={getFieldColor()}
          />
          {renderCorrectAnswers()}
        </CardContent>
        <CardActions>
          <QuicksandBtn
            color="primary"
            size="small"
            disabled={isCreatingAnswer}
            onClick={handleConfirm}
          >
            {isCreatingAnswer && (
              <CircularProgress size={14} sx={{ mr: 0.5 }} />
            )}
            {getCardButtonLabel()}
          </QuicksandBtn>
        </CardActions>
      </Card>
    </Box>
  );
};
