import { UnitsContextProviderV2 } from 'src/provider/units-context-provider-v2';
import CardManager from '../../modules/cards/pages/card-manager';

const cardsRouter = {
  path: 'progress',
  children: [
    {
      path: ':unitId/card/:index',
      element: (
        <UnitsContextProviderV2>
          <CardManager />
        </UnitsContextProviderV2>
      ),
    },
  ],
};

export default cardsRouter;
