import { useCallback, useContext, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
import { TTypeAudioReadCardFields } from '../../types';
import { useCreateAnswer } from '../../hooks/use-create-answer';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import useKeyPress from 'src/shared/hooks/use-key-press';

const QuicksandBtn = styled(IconButton)`
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const AudioRead = ({ card }: { card: TTypeAudioReadCardFields }) => {
  const [isListened, setIsListened] = useState(false);

  const { answerMutate, isCreatingAnswer, data } = useCreateAnswer();

  const { moveToNext } = useContext(CardsContextV2);

  const styles = {
    box: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5rem 0',
      paddingX: '18px',
    },
    card: {
      minHeight: '240px',
      maxWidth: '600px',
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginX: 'auto',
    },
    cardContent: {
      paddingX: '0px',
    },
    audioWrapper: {
      position: 'relative',
      minWidth: '250px',
      maxWidth: '400px',
      minHeight: '150px',
      maxHeight: '400px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage:
        'url(https://images.unsplash.com/photo-1476136236990-838240be4859?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '10px',
      padding: '50px 0',
      marginBottom: '10px',
    },
    audio: {
      display: 'none',
    },
    text: {
      marginTop: '20px',
    },
    correctAnswers: {
      marginTop: '10px',
    },
    audioPlayer: {
      '.rhap_main-controls-button svg': {
        color: '#ff5555',
      },
    },
  };

  const handleConfirm = useCallback(async () => {
    if (!isCreatingAnswer) {
      await moveToNext();
      answerMutate({
        card: card.id,
        response: '',
      });
    }
  }, [isCreatingAnswer, isListened, moveToNext, answerMutate, card.id]);

  function handleListen() {
    setIsListened(true);
  }

  useKeyPress(handleConfirm);

  return (
    <Box sx={styles.box}>
      <Card sx={styles.card}>
        <CardContent>
          <Box sx={{ mb: 1 }}>
            <AudioPlayer onListen={handleListen} src={card.images[0]} />
          </Box>

          {card.text ? (
            <Typography variant="body1" fontSize="16px" color="text.secondary">
              {card.text}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              fontSize="16px"
              color="text.secondary"
              dangerouslySetInnerHTML={{ __html: card.body }}
            />
          )}
        </CardContent>
        <CardActions>
          <QuicksandBtn
            color="primary"
            size="small"
            disabled={isCreatingAnswer}
            onClick={handleConfirm}
          >
            {isCreatingAnswer && (
              <CircularProgress size={14} sx={{ mr: 0.5 }} />
            )}
            Növbəti
          </QuicksandBtn>
        </CardActions>
      </Card>
    </Box>
  );
};

export default AudioRead;
