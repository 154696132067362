import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import ReactPlayer from 'react-player';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { TTypeImageWriteCardFields } from '../../types';
import { useCreateAnswer } from '../../hooks/use-create-answer';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
import useKeyPress from 'src/shared/hooks/use-key-press';

const QuicksandBtn = styled(IconButton)`
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const styles = {
  box: {
    width: '100%',
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingX: '18px',
  },
  mediaBox: {
    position: 'relative',
    minWidth: '250px',
    maxWidth: '400px',
    minHeight: '150px',
    maxHeight: '400px',
    marginX: '16px',
    marginTop: '20px',
    overflow: 'hidden',
    borderRadius: '10px',
  },
  videoPlayerIcon: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    inset: 0,
    zIndex: 111,
  },
  card: {
    minHeight: '300px',
    maxWidth: '600px',
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginX: 'auto',
  },
  correctAnswers: {
    marginTop: '10px',
  },
};

const VideoWrite = ({ card }: { card: TTypeImageWriteCardFields }) => {
  const { answerMutate, isCreatingAnswer, data } = useCreateAnswer();

  const { moveToNext } = useContext(CardsContextV2);

  const playerRef = useRef(null);

  const [playingVideo, setPlayingVideo] = useState(false);

  const [answerState, setAnswerState] = useState({
    corrects: [],
    isCorrect: false,
    tryCount: 0,
    answered: false,
    answerFieldValue: '',
    nextStep: false,
  });

  const handleEnded = () => {
    setPlayingVideo(false);
    playerRef.current.seekTo(0);
  };

  function handlePlayPause() {
    setPlayingVideo((prevState) => !prevState);
  }

  const handleConfirm = useCallback(() => {
    const {
      answerFieldValue,
      tryCount,
      isCorrect,
      answered,
      nextStep,
      corrects,
    } = answerState;

    if (answerFieldValue.trim() !== '' && tryCount !== 3) {
      setAnswerState((prevState) => ({
        ...prevState,
        tryCount: prevState.tryCount + 1,
      }));
    }

    if (
      isCorrect ||
      tryCount === 2 ||
      (tryCount === 0 && answered) ||
      (tryCount > 0 && corrects.length > 0 && answered && nextStep)
    ) {
      moveToNext();
      setAnswerState({
        corrects: [],
        isCorrect: false,
        tryCount: 0,
        answered: false,
        answerFieldValue: '',
        nextStep: false,
      });
    } else if (
      answerFieldValue.trim() === '' &&
      tryCount > 0 &&
      !isCorrect &&
      answered
    ) {
      answerMutate({
        card: card.id,
        response: answerFieldValue.toLowerCase(),
      });
      setAnswerState((prevState) => ({
        ...prevState,
        nextStep: true,
      }));
    } else {
      answerMutate({
        card: card.id,
        response: answerFieldValue.toLowerCase(),
      });
    }
  }, [answerState, moveToNext, setAnswerState, answerMutate, card.id]);

  useEffect(() => {
    if (data) {
      const answerData = data?.data?.data;

      setAnswerState((prevState) => {
        return {
          ...prevState,
          corrects: answerData.corrects,
          isCorrect: answerData.isCorrect,
          answered: true,
        };
      });
    }
  }, [data]);

  function getDisabledField() {
    return (
      answerState.isCorrect ||
      (answerState.answered && answerState.tryCount === 3) ||
      (answerState.answerFieldValue.trim() === '' &&
        answerState.corrects.length > 0 &&
        !answerState.isCorrect &&
        answerState.tryCount === 0) ||
      answerState.nextStep
    );
  }

  function getCardButtonLabel() {
    if (
      (answerState.answered && answerState.tryCount === 2) ||
      answerState.isCorrect ||
      (answerState.answerFieldValue.trim() === '' &&
        answerState.corrects.length > 0 &&
        !answerState.isCorrect &&
        answerState.tryCount === 0) ||
      answerState.nextStep
    ) {
      return 'Növbəti';
    } else if (
      answerState.answerFieldValue.trim() !== '' &&
      answerState.tryCount < 2
    ) {
      return answerState.tryCount === 0 ? 'Yoxla' : 'Yenidən sına';
    } else {
      return 'Bilmirəm';
    }
  }

  function getFieldColor() {
    if (answerState.isCorrect) {
      return 'success';
    } else if (
      answerState.answerFieldValue.trim() !== '' &&
      answerState.answered &&
      !answerState.isCorrect
    ) {
      return 'error';
    } else {
      return 'primary';
    }
  }

  function renderCorrectAnswers() {
    const {
      answerFieldValue,
      isCorrect,
      answered,
      corrects,
      tryCount,
      nextStep,
    } = answerState;

    const isCorrectAnswer =
      (answerFieldValue?.length === 0 && answered && tryCount === 0) ||
      (!isCorrect && tryCount === 2) ||
      (isCorrect && !isCreatingAnswer) ||
      (nextStep && !isCreatingAnswer);

    return (
      isCorrectAnswer && (
        <Typography sx={styles.correctAnswers}>
          {answerState.isCorrect
            ? 'Doğrudur'
            : isCreatingAnswer
            ? ''
            : `Doğru cavab: ${corrects[0]}`}
        </Typography>
      )
    );
  }
  useKeyPress(handleConfirm);

  return (
    <Box sx={styles.box}>
      <Card sx={styles.card}>
        <Box sx={{ marginX: 'auto' }}>
          <Box sx={styles.mediaBox}>
            <ReactPlayer
              ref={playerRef}
              url={card?.images[0]}
              controls={false}
              width="100%"
              height="100%"
              playing={playingVideo}
              onEnded={handleEnded}
            />
            <Box sx={styles.videoPlayerIcon} className="videoPlayerIcon">
              <IconButton onClick={handlePlayPause} color="primary">
                {playingVideo ? (
                  <PauseCircleOutlineIcon
                    style={{ color: '#fff' }}
                    fontSize="large"
                  />
                ) : (
                  <PlayCircleIcon style={{ color: '#fff' }} fontSize="large" />
                )}
              </IconButton>
            </Box>
          </Box>
        </Box>
        <CardContent>
          {card.text ? (
            <Typography variant="body1" fontSize="16px" color="text.secondary">
              {card.text}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              fontSize="16px"
              color="text.secondary"
              dangerouslySetInnerHTML={{ __html: card.body }}
            />
          )}
          <TextField
            fullWidth={true}
            value={answerState.answerFieldValue}
            onChange={(e) =>
              setAnswerState((prevState) => ({
                ...prevState,
                answerFieldValue: e.target.value,
              }))
            }
            type="text"
            inputProps={{
              disabled: getDisabledField(),
            }}
            focused={answerState.answered}
            color={getFieldColor()}
            placeholder='cavabı yazın'
          />
          {renderCorrectAnswers()}
        </CardContent>
        <CardActions>
          <QuicksandBtn
            color="primary"
            size="small"
            disabled={isCreatingAnswer}
            onClick={handleConfirm}
          >
            {isCreatingAnswer && (
              <CircularProgress size={14} sx={{ mr: 0.5 }} />
            )}
            {getCardButtonLabel()}
          </QuicksandBtn>
        </CardActions>
      </Card>
    </Box>
  );
};

export default VideoWrite;
