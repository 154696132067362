import { AxiosResponse } from 'axios';
import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const createAnswer = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/answers`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
