import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
import { TTypeAudioWriteCardFields } from '../../types';
import { useCreateAnswer } from '../../hooks/use-create-answer';
import useKeyPress from 'src/shared/hooks/use-key-press';

const QuicksandBtn = styled(IconButton)`
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const AudioWrite = ({ card }: { card: TTypeAudioWriteCardFields }) => {
  const { answerMutate, isCreatingAnswer, data } = useCreateAnswer();
  const { moveToNext } = useContext(CardsContextV2);

  const [answerState, setAnswerState] = useState({
    corrects: [],
    isCorrect: false,
    tryCount: 0,
    answered: false,
    answerFieldValue: '',
    nextStep: false,
  });

  const audioRef = useRef(null);
  const [audioPlaying, setAudioPlaying] = useState(true);

  const styles = {
    box: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5rem 0',
      paddingX: '18px',
    },
    card: {
      minHeight: '240px',
      maxWidth: '600px',
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginX: 'auto',
    },
    cardContent: {
      paddingX: '0px',
    },
    audioWrapper: {
      position: 'relative',
      minWidth: '250px',
      maxWidth: '400px',
      minHeight: '150px',
      maxHeight: '400px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage:
        'url(https://images.unsplash.com/photo-1476136236990-838240be4859?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '10px',
      padding: '50px 0',
      marginBottom: '10px',
    },
    audio: {
      display: 'none',
    },
    text: {
      marginTop: '20px',
    },
    correctAnswers: {
      marginTop: '10px',
    },
    audioPlayer: {
      '.rhap_main-controls-button svg': {
        color: '#ff5555',
      },
    },
  };

  const handleConfirm = useCallback(() => {
    const {
      answerFieldValue,
      tryCount,
      isCorrect,
      answered,
      nextStep,
      corrects,
    } = answerState;

    if (answerFieldValue.trim() !== '' && tryCount !== 3) {
      setAnswerState((prevState) => ({
        ...prevState,
        tryCount: prevState.tryCount + 1,
      }));
    }

    if (
      isCorrect ||
      tryCount === 2 ||
      (tryCount === 0 && answered) ||
      (tryCount > 0 && corrects.length > 0 && answered && nextStep)
    ) {
      moveToNext();
      setAnswerState({
        corrects: [],
        isCorrect: false,
        tryCount: 0,
        answered: false,
        answerFieldValue: '',
        nextStep: false,
      });
    } else if (
      answerFieldValue.trim() === '' &&
      tryCount > 0 &&
      !isCorrect &&
      answered
    ) {
      answerMutate({
        card: card.id,
        response: answerFieldValue.toLowerCase(),
      });
      setAnswerState((prevState) => ({
        ...prevState,
        nextStep: true,
      }));
    } else {
      answerMutate({
        card: card.id,
        response: answerFieldValue.toLowerCase(),
      });
    }
  }, [answerState, moveToNext, setAnswerState, answerMutate, card.id]);

  useEffect(() => {
    if (data) {
      const answerData = data?.data?.data;

      setAnswerState((prevState) => {
        return {
          ...prevState,
          corrects: answerData.corrects,
          isCorrect: answerData.isCorrect,
          answered: true,
        };
      });
    }
  }, [data]);

  const handleAudioPlay = () => {
    setAudioPlaying(true);
  };

  const handleAudioEnded = () => {
    setAudioPlaying(false);
  };

  function getDisabledField() {
    return (
      answerState.isCorrect ||
      (answerState.answered && answerState.tryCount === 3) ||
      (answerState.answerFieldValue.trim() === '' &&
        answerState.corrects.length > 0 &&
        !answerState.isCorrect &&
        answerState.tryCount === 0) ||
      answerState.nextStep
    );
  }

  function getCardButtonLabel() {
    if (
      (answerState.answered && answerState.tryCount === 2) ||
      answerState.isCorrect ||
      (answerState.answerFieldValue.trim() === '' &&
        answerState.corrects.length > 0 &&
        !answerState.isCorrect &&
        answerState.tryCount === 0) ||
      answerState.nextStep
    ) {
      return 'Növbəti';
    } else if (
      answerState.answerFieldValue.trim() !== '' &&
      answerState.tryCount < 2
    ) {
      return answerState.tryCount === 0 ? 'Yoxla' : 'Yenidən sına';
    } else {
      return 'Bilmirəm';
    }
  }

  const getFieldColor = () => {
    if (answerState.corrects?.length > 0) {
      return answerState.isCorrect ? 'success' : 'error';
    } else {
      return 'primary';
    }
  };

  function renderCorrectAnswers() {
    const {
      answerFieldValue,
      isCorrect,
      answered,
      corrects,
      tryCount,
      nextStep,
    } = answerState;

    const isCorrectAnswer =
      (answerFieldValue?.length === 0 && answered && tryCount === 0) ||
      (!isCorrect && tryCount === 2) ||
      (isCorrect && !isCreatingAnswer) ||
      (nextStep && !isCreatingAnswer);

    return (
      isCorrectAnswer && (
        <Typography sx={styles.correctAnswers}>
          {answerState.isCorrect
            ? 'Doğrudur'
            : isCreatingAnswer
            ? ''
            : `Doğru cavab: ${corrects[0]}`}
        </Typography>
      )
    );
  }

  useKeyPress(handleConfirm);

  return (
    <Box sx={styles.box}>
      <Card sx={styles.card}>
        <CardContent>
          <Box sx={{ mb: 1 }}>
            <AudioPlayer
              src={card.images[0]}
              ref={audioRef}
              onPlay={handleAudioPlay}
              onEnded={handleAudioEnded}
            />
          </Box>

          {card.text ? (
            <Typography variant="body1" fontSize="16px" color="text.secondary">
              {card.text}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              fontSize="16px"
              color="text.secondary"
              dangerouslySetInnerHTML={{ __html: card.body }}
            />
          )}
          <Box sx={styles.text}>
            {
              <TextField
                value={answerState.answerFieldValue}
                onChange={(e) =>
                  setAnswerState((prevState) => ({
                    ...prevState,
                    answerFieldValue: e.target.value,
                  }))
                }
                inputProps={{
                  disabled: getDisabledField(),
                }}
                focused={answerState.corrects?.length > 0}
                fullWidth={true}
                color={getFieldColor()}
              />
            }
          </Box>
          {renderCorrectAnswers()}
        </CardContent>
        <CardActions>
          <QuicksandBtn
            color="primary"
            size="small"
            sx={{ fontSize: '14px' }}
            disabled={isCreatingAnswer}
            onClick={handleConfirm}
          >
            {isCreatingAnswer && (
              <CircularProgress size={14} sx={{ mr: 0.5 }} />
            )}
            {getCardButtonLabel()}
          </QuicksandBtn>
        </CardActions>
      </Card>
    </Box>
  );
};

export default AudioWrite;
