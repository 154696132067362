import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import type { ReactNode } from 'react';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'heading.general',
    items: [
      // {
      //   name: 'lessons.title',
      //   icon: MenuBookIcon,
      //   link: '/dashboard/units',
      // },
      {
        name: 'digests.title',
        icon: LocalLibraryIcon,
        link: '/dashboard/proqramlar',
      },
      {
        name: 'teachers',
        icon: PeopleAltIcon,
        link: '/dashboard/teachers',
      },
      // {
      //   name: 'myResults.title',
      //   icon: FactCheckIcon,
      //   link: '/dashboard/my-results',
      // },
      // {
      //   name: 'myFriends.title',
      //   icon: Diversity3Icon,
      //   link: '/dashboard/my-friends',
      // },
    ],
  },
];

export default menuItems;
