import { useContext } from 'react';

import { Box, Card, IconButton, Tooltip, alpha, styled } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import LanguageSwitcher from 'src/layouts/CollapsedSidebarLayout/Header/Buttons/LanguageSwitcher';
import Logo from 'src/shared/components/Logo';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    background-color: ${theme.colors.alpha.white[70]};
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: flex-end;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
    z-index: 999;
`,
);

function Header() {
  return (
    <HeaderWrapper>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ width: '100%' }}
      >
        <Logo />
        <LanguageSwitcher />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
