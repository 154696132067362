import { Tooltip, Typography } from '@mui/material';

type TEllipsTypography = {
  variant?: string;
  color?: string;
  style?: string;
  content: string;
};

const EllipsTypography = ({
  variant = 'body2',
  color = 'text.secondary',
  style,
  content,
}: TEllipsTypography) => {
  return (
    <Tooltip
      title={content}
      placement="top"
      disableHoverListener={content?.length < 70}
    >
      <Typography
        style={{
          fontFamily: "'Quicksand', sans-serif",
          fontSize: '14px',
          fontWeight: 500,
          minHeight: '40px',
          maxHeight: '80px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          padding: '0 10px 0 0',
        }}
      >
        {content}
      </Typography>
    </Tooltip>
  );
};

export default EllipsTypography;
