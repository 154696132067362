import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import './index.css';
import { AlertProvider } from './shared/contexts/Alert';
import { ConfirmationModalProvider } from './shared/contexts/confirmation-modal';

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AlertProvider>
      <ConfirmationModalProvider>
        <HelmetProvider>
          <Provider store={store}>
            <SidebarProvider>
              <BrowserRouter>
                <ScrollTop />
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </SidebarProvider>
          </Provider>
        </HelmetProvider>
      </ConfirmationModalProvider>
    </AlertProvider>
  </QueryClientProvider>,

  document.getElementById('root'),
);

serviceWorker.unregister();
