import { MyFriends } from '../pages/my-friends';

const myFriendsRouter = {
  path: 'my-friends',
  children: [
    {
      path: '',
      element: <MyFriends />,
    },
  ],
};

export default myFriendsRouter;
