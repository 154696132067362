import { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
import { queryError } from 'src/shared/utils/query-error';
import { getUnitService } from '../services';

export const useUnit = () => {
  const { unitId } = useParams();

  const { updateCards } = useContext(CardsContextV2);

  const { data, isLoading } = useQuery(
    ['get-unit', unitId],
    () => {
      if (unitId) {
        return getUnitService(unitId);
      }
    },
    {
      select: ({ data }: any) => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  useEffect(() => {
    if (data) {
      updateCards(data);
    }
  }, [data]);

  return {
    data,
    isLoading,
  };
};
