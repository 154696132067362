import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getDigests } from '../services';
// import { TDigestsRoot } from '../../../types';

export const useDigests = () => {
  const { data, isLoading } = useQuery({
    queryKey: 'digests-get-all',
    queryFn: getDigests,
    select: ({ data }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  return {
    data,
    isLoading,
  };
};
