import { FC, createContext, useReducer } from 'react';

export type configType = {
  isOpen: boolean;
  message: string;
  buttonMessage: string;
  color: string;
};

export type AlertType = {
  state: any;
  dispatch: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConfirmationModalContext = createContext<AlertType>(
  {} as AlertType,
);

type confirmationReducerAction =
  | {
      type: 'open';
      payload: {
        isOpen: boolean;
        message: string;
        buttonMessage:string;
        color: string;
        confirm: any;
      };
    }
  | {
      type: 'close';
    };

type State = {
  isOpen: boolean;
  message: string;
  buttonMessage: string;
  color: string;
  handleConfirm: any;
};

function confirmationReducer(state: State, action: confirmationReducerAction) {
  if (action.type === 'open') {
    return {
      isOpen: action.payload.isOpen,
      message: action.payload.message,
      buttonMessage: action.payload.buttonMessage,
      color: action.payload.color,
      handleConfirm: action.payload.confirm,
    };
  }
  if (action.type === 'close') {
    return {
      ...state,
      isOpen: false,
    };
  } else {
    return state;
  }
}

export const ConfirmationModalProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(confirmationReducer, {
    isOpen: false,
    message: 'string',
    buttonMessage: 'string',
    color: 'error',
    handleConfirm: () => {},
  });

  return (
    <ConfirmationModalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ConfirmationModalContext.Provider>
  );
};
