import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
import { ICard } from 'src/types';

export const UnitsContextProviderV2 = ({ children }) => {
  const [cards, setCards] = useState<ICard[]>([]);
  const [index, setIndex] = useState<number>(0);
  const { index: lastCardIndex } = useParams();
  useEffect(() => {
    if (cards?.length) {
      // const lastCardIndex = cards?.findIndex((c) => c.isLast);
      // const lastCard = cards?.find((c) => c.isLast);
      
      setIndex(lastCardIndex ? Number(lastCardIndex) : 0);
    }
  }, [cards]);

  function updateCards(newCards) {
    setCards(newCards);
  }
  function moveToNext() {
    setIndex((s) => s + 1);
  }

  return (
    <CardsContextV2.Provider
      value={{
        cards,
        index,
        updateCards,
        moveToNext,
      }}
    >
      {children}
    </CardsContextV2.Provider>
  );
};
