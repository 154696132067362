import TeachersPage from '../pages/teachers';

const teachersRouter = {
  path: 'teachers',
  children: [
    {
      path: '',
      element: <TeachersPage />,
    },
  ],
};

export default teachersRouter;
