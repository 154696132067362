import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  CardActions,
  Grid,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { IUnit } from 'src/types';
import LockIcon from '@mui/icons-material/Lock';
import styled from '@emotion/styled';
import { imageLazyLoading } from 'src/shared/components/image-lazy-loading';
import EllipsTypography from 'src/shared/components/ellips-typography';

const LinearProgressWrapper = styled(LinearProgress)(
  ({ theme }: any) => `
        flex-grow: 1;
        margin-right: ${theme.spacing(1)};
`,
);

export const UnitBox = ({ unit }: { unit: IUnit }) => {
  const { t } = useTranslation();

  const { currentImage, loading } = imageLazyLoading(unit?.image);

  const percentage = unit?.percentage ? Math.floor(unit?.percentage * 100) : 0;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Card
          sx={{
            minHeight: '300px',
            opacity: unit.locked && '0.6',
            pointerEvents: unit.locked ? 'none' : 'initial',
          }}
        >
          <CardMedia
            style={{
              filter: `${loading ? 'blur(20px)' : ''}`,
              transition: '0.4s filter linear',
              width: '100%',
              background: '#ffffff',
            }}
            component="img"
            height="180"
            image={currentImage}
            alt={unit.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {unit.title}
            </Typography>
            <Tooltip
              title={unit.description}
              placement="bottom"
              disableHoverListener={unit.description?.length < 70}
            >
              <EllipsTypography content={unit.description} />
            </Tooltip>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              sx={{
                flexGrow: 1,
                mt: 1,
              }}
            >
              <LinearProgressWrapper
                value={percentage}
                color="primary"
                variant="determinate"
              />
              <Typography variant="h4" color="text.primary">
                {percentage}%
              </Typography>
            </Box>
          </CardContent>
          <CardActions>
            <Button
              component={Link}
              to={`/dashboard/progress/${unit.id}`}
              variant="outlined"
              fullWidth
              style={{
                fontFamily: "'Quicksand', sans-serif",
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              {t('buttons.continue')}
            </Button>
          </CardActions>
        </Card>
        {unit.locked && (
          <Box sx={{ position: 'absolute', top: '.5rem', right: '.5rem' }}>
            <LockIcon fontSize={'medium'} />
          </Box>
        )}
      </Box>
    </Grid>
  );
};
