import { AxiosResponse } from 'axios';

import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getUnits = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/units/user`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getUnitService = async (
  unitId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/units/${unitId}/cards/`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
