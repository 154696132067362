import { Units } from '../pages/units';

const unitsRouter = {
  path: 'units',
  children: [
    {
      path: '',
      element: <Units />,
    },
  ],
};

export default unitsRouter;
