import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { useCallback, useContext } from 'react';
import { TImageQuizCardFields } from '../../types';
import { imageLazyLoading } from 'src/shared/components/image-lazy-loading';
import Progressbar from 'src/modules/progressbar';
import { CardsContextV2 } from 'src/contexts/cards-context-v2';
import { useCreateAnswer } from '../../hooks/use-create-answer';
import useKeyPress from 'src/shared/hooks/use-key-press';

const QuicksandBtn = styled(IconButton)`
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

export const Text = ({ card }: { card: TImageQuizCardFields }) => {
  const { t } = useTranslation();

  const { moveToNext } = useContext(CardsContextV2);
  const { answerMutate } = useCreateAnswer();

  const styles = {
    box: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5rem 0',
      paddingX: '18px',
    },
    mediaBox: {
      minWidth: '250px',
      maxWidth: '400px',
      minHeight: '150px',
      maxHeight: '400px',
      marginX: '16px',
      marginTop: '20px',
      overflow: 'hidden',
      borderRadius: '10px',
    },
    card: {
      minHeight: '300px',
      maxWidth: '600px',
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginX: 'auto',
    },
    cardContent: {
      paddingX: '0px',
    },
  };

  const handleClick = useCallback(() => {
    answerMutate({
      card: card.id,
      response: '',
    });
    moveToNext();
  }, [answerMutate, card.id, moveToNext]);

  useKeyPress(handleClick);

  return (
    <>
      <Box sx={styles.box}>
        <Card sx={styles.card}>
          <CardContent>
            {card.text ? (
              <Typography
                variant="body1"
                fontSize="16px"
                color="text.secondary"
              >
                {card.text}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                fontSize="16px"
                color="text.secondary"
                dangerouslySetInnerHTML={{ __html: card.body }}
              />
            )}
          </CardContent>
          <CardActions>
            <QuicksandBtn onClick={handleClick} color="primary" size="small">
              Davam et
            </QuicksandBtn>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};
