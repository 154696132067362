import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';

import dashboardsRoutes from './dashboards';
import accountRoutes from './account';
import baseRoutes from './base';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import termsRouter from 'src/modules/terms/router';

const router: RouteObject[] = [
  {
    path: '',
    children: [...accountRoutes, ...baseRoutes],
  },
  termsRouter,
  {
    path: '/dashboard',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [...dashboardsRoutes],
  },
];

export default router;
