import { useContext } from 'react';
import { useMutation } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { createAnswer } from '../services';

export const useCreateAnswer = () => {
  const { handleMessage } = useContext(AlertContext);

  const {
    mutate: answerMutate,
    isLoading: isCreatingAnswer,
    data,
  } = useMutation('create-answer', createAnswer, {
    onSuccess: (e: any) => {
      if (e.status === 201) {
        // setStatus((s) => {
        //   return true;
        // });
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Unknown error';

      handleMessage(true, errorMsg, 'error');

      // setStatus((s) => {
      //   return false;
      // });
    },
  });

  return {
    data,
    answerMutate,
    isCreatingAnswer,
  };
};
