import {
  Box,
  Card,
  Divider,
  Link,
  Typography,
  alpha,
  styled,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import Header from 'src/content/pages/Auth/Login/Cover/header';

const CardWrapper = styled(Box)(
  ({ theme }) => `
      background: ${alpha(theme.colors.primary.main, 0.05)};
  `,
);

function TermsPage() {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingX: '20px',
          marginTop: '150px',
          paddingBottom: '100px',
        }}
      >
        <Card variant="outlined" sx={{ maxWidth: '1000px' }}>
          <CardWrapper
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            sx={{
              pt: 3,
              px: 3,
              pb: 7,
            }}
          >
            <Typography variant="h3" fontSize={16} textAlign={'center'}>
              Şərtlər və Qaydalar
            </Typography>
            <Box>
              <Typography variant="h4" fontSize={16}>
                1. Giriş
              </Typography>
              <Typography variant="subtitle2">
                Learne-yə xoş gəlmisiniz! Tətbiqimizdən istifadə etməklə bu
                şərtləri qəbul etdiyinizi təsdiqləyirsiniz. Zəhmət olmasa,
                diqqətlə oxuyun.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                2. Məlumatların Toplanması
              </Typography>
              <Typography variant="subtitle2">
                İstifadə təcrübənizi yaxşılaşdırmaq üçün öyrənmə seçimlərinizi,
                applikasiyada irəliləyişlərinizi məlumat olaraq toplaya bilərik.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                3. Məlumat Təhlükəsizliyi
              </Typography>
              <Typography variant="subtitle2">
                Məlumatlarınızı qorumaq üçün məsuliyyət daşıyırıq. Sizin
                razılığınız olmadan və ya qanun tələb etmədikcə, məlumatlarınız
                üçüncü tərəflərlə paylaşılmayacaq və təhlükəsiz şəkildə
                saxlanacaqdır.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                4. İstifadəçi Məsuliyyətləri
              </Typography>
              <Typography variant="subtitle2">
                <ul style={{ marginTop: '5px' }}>
                  <li>Qeydiyyat zamanı dəqiq məlumat təqdim edin.</li>
                  <li>
                    Tətbiqdən yalnız ingilis dili öyrənmək məqsədilə istifadə
                    edin.
                  </li>
                  <li>Hesab məlumatlarınızı başqaları ilə paylaşmayın.</li>
                </ul>
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                5. Şərtlərin Yenilənməsi
              </Typography>
              <Typography variant="subtitle2">
                Bu şərtləri zaman-zaman yeniləyə bilərik. Əhəmiyyətli
                dəyişikliklər e-poçt və ya tətbiq vasitəsilə bildiriləcək.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                6. Bizimlə Əlaqə
              </Typography>
              <Typography variant="subtitle2">
                Bu şərtlərlə bağlı sual və ya narahatlığınız varsa, zəhmət
                olmasa “
                <Link
                  href={'mailto:contact.learne.app@gmail.com'}
                  variant="subtitle1"
                >
                  contact.learne.app@gmail.com
                </Link>
                ” vasitəsilə bizimlə əlaqə saxlayın.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                7. E-poçt ünvanı və bu məlumatlardan İstifadə
              </Typography>
              <Typography variant="subtitle2">
                <ul style={{ marginTop: '5px' }}>
                  <li>
                    <Typography
                      variant="h4"
                      fontSize={16}
                      sx={{ display: 'inline' }}
                    >
                      E-poçt Ünvanı:
                    </Typography>{' '}
                    Qeydiyyat zamanı hesabınızı idarə etmək və sizinlə ünsiyyət
                    qurmaq üçün e-poçt ünvanınızı toplayırıq.
                  </li>
                  <li>
                    <Typography variant="h4" fontSize={16}>
                      Məlumatlardan İstifadə:
                    </Typography>{' '}
                    <ul>
                      <li>
                        E-poçt ünvanınızı aşağıdakı məqsədlər üçün istifadə
                        edirik:
                        <ul>
                          <li>Hesabınızla bağlı yeniliklər göndərmək.</li>
                          <li>Tədris materialları və tövsiyələr paylaşmaq.</li>
                          <li>
                            Aksiyalar, tədbirlər və ya yeni xüsusiyyətlər
                            haqqında məlumat vermək.
                          </li>
                        </ul>
                      </li>
                      <li>
                        İstənilən vaxt göndərilən "abunədən çıx" keçidinə
                        klikləməklə reklam e-poçtlarından imtina edə bilərsiniz.
                      </li>
                    </ul>
                  </li>
                </ul>
              </Typography>
            </Box>
            <Divider />
            <Typography variant="h3" fontSize={16} textAlign={'center'}>
              Məxfilik Siyasəti
            </Typography>
            <Box>
              <Typography variant="h4" fontSize={16}>
                1. Giriş
              </Typography>
              <Typography variant="subtitle2">
                Bu məxfilik siyasəti məlumatlarınızı necə topladığımızı,
                istifadə etdiyimizi və qoruduğumuzu izah edir.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                2. Toplanan Məlumatlar
              </Typography>
              <Typography variant="subtitle2">
                <ul style={{ marginTop: '5px' }}>
                  <li>Qeydiyyat zamanı təqdim etdiyiniz e-poçt ünvanı.</li>
                  <li>Öyrənmə seçimləri və istifadə statistikası.</li>
                </ul>
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                3. Məlumatların Paylaşılması
              </Typography>
              <Typography variant="subtitle2">
                Məlumatlarınız qanunla tələb edilmədikcə və ya sizin açıq
                razılığınız olmadan üçüncü tərəflərlə paylaşılmır.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                4. Hüquqlarınız
              </Typography>
              <Typography variant="subtitle2">
                <ul style={{ marginTop: '5px' }}>
                  <li>
                    <Typography
                      variant="h4"
                      fontSize={16}
                      sx={{ display: 'inline' }}
                    >
                      Giriş:
                    </Typography>{' '}
                    Şəxsi məlumatlarınıza daxil olmaq üçün sorğu göndərə
                    bilərsiniz.
                  </li>
                  <li>
                    <Typography
                      variant="h4"
                      fontSize={16}
                      sx={{ display: 'inline' }}
                    >
                      Yeniləmə:
                    </Typography>{' '}
                    Tətbiq daxilində hesab məlumatlarınızı yeniləyə bilərsiniz.
                  </li>
                  <li>
                    <Typography
                      variant="h4"
                      fontSize={16}
                      sx={{ display: 'inline' }}
                    >
                      Silinmə:
                    </Typography>{' '}
                    Hesabınızın və əlaqəli məlumatlarınızın silinməsini tələb
                    edə bilərsiniz.
                  </li>
                </ul>
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                5. Təhlükəsizlik
              </Typography>
              <Typography variant="subtitle2">
                Məlumatlarınızı icazəsiz giriş, itki və ya sui-istifadədən
                qorumaq üçün texniki və təşkilati tədbirlər həyata keçiririk.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" fontSize={16}>
                6. Bizimlə Əlaqə
              </Typography>
              <Typography variant="subtitle2">
                Məxfiliklə bağlı sorğular üçün “
                <Link
                  href={'mailto:contact.learne.app@gmail.com'}
                  variant="subtitle1"
                >
                  contact.learne.app@gmail.com
                </Link>
                ” vasitəsilə bizə müraciət edə bilərsiniz.
              </Typography>
            </Box>
            <Typography variant="subtitle2">
              Qeydiyyatdan keçməklə, Şərtlər və Qaydalarımızı və Məxfilik
              Siyasətimizi oxuduğunuzu və qəbul etdiyinizi təsdiqləyirsiniz.
            </Typography>
          </CardWrapper>
        </Card>
      </Box>
    </>
  );
}

export default TermsPage;
